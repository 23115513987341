<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 10.5 1.75">
    <path
      :style="iconStyle"
      d="M10.063-6.125A.426.426,0,0,1,10.377-6a.426.426,0,0,1,.123.314v.875a.426.426,0,0,1-.123.314.426.426,0,0,1-.314.123H.438A.426.426,0,0,1,.123-4.5.426.426,0,0,1,0-4.812v-.875A.426.426,0,0,1,.123-6a.426.426,0,0,1,.314-.123Z"
      transform="translate(0 6.125)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '1.75',
    },
    width: {
      type: String,
      default: '10.5',
    },
    color: {
      type: String,
      default: 'label-text',
    },
  },
  computed: {
    iconStyle() {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
